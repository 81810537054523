#page.video.show{
  .category-title{
    max-width: 1240px;
    border-bottom: 1px solid #999;
    font-size: 20px;
    font-weight: 400;
    margin-top: 30px;
  }
  .video-content{
    margin-top: 30px;
    .title{
      font-size: 20px;
      font-weight: bold;
    }
    max-width: 1240px;
    line-height: 40px;
    .video-wrap{
      video{
        width: 100%;
      }
    }
  }
}

#page.video.list{
  .video-page{
    margin-top: 30px;
    nav{

      margin: 0 auto;
    }
  }
  .video-list{
    margin-top: 40px;
    max-width: 1240px;
  }
  .video-info{
    overflow: hidden;
    video{
      height: 540px;
    }
  }

  .video-items{
    height:540px;
    overflow: auto;
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
    overflow-x: hidden;
    overflow-y: auto;
  }
  .topic-list{
    margin-top: 30px;
    img {
      width: 100%;
    }
    p{
      text-align: center;
    }
  }

  .video-images{
    .col-3{
      margin-top: 20px;
    }
  }
}